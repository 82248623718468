@keyframes up-and-down {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-3px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 100%;
    transform: translateY(0px);
  }
}

@keyframes appear-right {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes appear-left {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.appear1 {
  animation: appear 1s linear;
}

.appear2 {
  animation: appear 2s linear;
}

.appear3 {
  animation: appear 3s linear;
}

.appear4 {
  animation: appear 3.5s linear;
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  animation: appear-right 1s ease;
  opacity: 1;
}
